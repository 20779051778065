import Badge from '@/interfaces/Badge';
import IBadgeDto from '@interfaces/IBadgeDto';

const badgeAdapter = (badge: IBadgeDto): Badge => {
  const { 
    id,
    ...rest
  } = badge;
  
  return {
    id: +id,
    ...rest,
  };
};

export default badgeAdapter;

