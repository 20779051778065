import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const CreateSolpedPage = lazy(() => import('@/containers/Views/Solpeds/SolpedsForm/CreateSolpedPage'));
const EditSolpedPage = lazy(() => import('@/containers/Views/Solpeds/SolpedsForm/EditSolpedPage'));
const SolpedPage = lazy(() => import('@/containers/Views/Solpeds/SolpedPage'));
const SolpedDetailsPage = lazy(() => import('@/containers/Views/Solpeds/SolpedDetailsPage'));
const WorkSupplyOrders = lazy(() => import('@/containers/Views/SupplyOrders/WorkSupplyOrders'));
const ProcessMultipleSolpeds = lazy(() => import('@/containers/Views/SupplyOrders/ProcessMultipleSolpeds'));

const SolpedsRouter = (
  <Route path="solpeds">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Solpeds} 
          permissions={EBasicActions.Find}
        >
          <SolpedPage />
        </PrivateRoute>
      )} 
    />
    <Route 
      path=":solpedId"
      element={(
        <PrivateRoute 
          pageName={EPage.Solpeds} 
          permissions={EBasicActions.Find}
        >
          <SolpedDetailsPage />
        </PrivateRoute>
      )}
    />
    <Route 
      path="edit/:solpedId"
      element={(
        <PrivateRoute 
          pageName={EPage.Solpeds} 
          permissions={EBasicActions.Edit}
        >
          <EditSolpedPage />
        </PrivateRoute>
      )}
    />
    <Route 
      path="create"
      element={(
        <PrivateRoute 
          pageName={EPage.Solpeds} 
          permissions={EBasicActions.Create}
        >
          <CreateSolpedPage />
        </PrivateRoute>
      )}
    />
    <Route 
      path="process"
      element={(
        <PrivateRoute 
          pageName={EPage.SupplyOrders} 
          permissions={EBasicActions.Create}
        >
          <ProcessMultipleSolpeds />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":solpedId/supply_order"
      element={(
        <PrivateRoute 
          pageName={EPage.SupplyOrders} 
          permissions={EBasicActions.Create}
        >
          <WorkSupplyOrders />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default SolpedsRouter;
