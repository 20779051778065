import React, {
  ReactElement, useMemo, useRef, useState, useEffect, forwardRef,
} from 'react';
import {
  Avatar,
  Empty, message, Select, Spin, Tag,
} from 'antd';
import type { SelectProps } from 'antd';
import debounce from 'lodash/debounce';
import { RefSelectProps } from 'antd/lib/select';
import useCustomTranslation from '@/hooks/useCustomTranslation';

export interface selectOptions {
  value: number | string;
  label: string;
  tag?: string;
  imageUrl?: string;
  disabled?: boolean;
}
export interface DebounceSelectProps<T> {
  fetchOptions: (search: any) => Promise<T[]>;
  refactFunc: (list: T[]) => SelectProps['options'] | selectOptions[];
  debounceTimeout?: number;
  initialOptions?: SelectProps['options'] | selectOptions[];
  onSelect?: (e: any) => void;
  onClear?: () => void;
  onDeselect?: (e: any) => void;
  onSearch?: (e: string) => void;
  onChange?: any;
  mode?: 'multiple' | 'tags';
  dropdownRender?: (menu: any) => React.ReactElement<any>;
  searchValue?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  // eslint-disable-next-line no-restricted-globals
  open?: boolean;
  disabled?: boolean;
  inputRef?: React.Ref<RefSelectProps>,
  forceReload?: boolean;
  useAvatar?: boolean;
  dependencies?: unknown[];
}


const DebounceSelect = <T, >({
  debounceTimeout = 400,
  refactFunc,
  fetchOptions,
  initialOptions,
  onSelect,
  onDeselect,
  onClear,
  onChange,
  mode,
  dropdownRender,
  onSearch,
  searchValue,
  placeholder,
  style,
  open,
  disabled = false,
  inputRef,
  forceReload,
  useAvatar = false,
  dependencies = [],
  ...props
}: DebounceSelectProps<T>) => {
  const { t } = useCustomTranslation();

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<SelectProps['options'] | []>(initialOptions || []);
  const [notFoundContent, setNotFoundContent] = useState<ReactElement | null>(null);
  const fetchRef = useRef(0);
  const { Option } = Select;

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: any) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      setOptions([]);
      setLoading(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        const refactorOptions = refactFunc(newOptions);
        setOptions(refactorOptions);
        setLoading(false);
      }).catch((e) => {
        setOptions([]);
        setLoading(false);
      message.error(t('g.error'));
      });
    };
    return debounce(loadOptions, debounceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOptions, debounceTimeout]);

  const selectNotFoundContent = (): void => {
    if (loading) {
      setNotFoundContent(<Spin size="small" />);
    } else if (options?.length === 0) {
      setNotFoundContent(<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('g.no_data')} />);
    } else {
      setNotFoundContent(null);
    }
  };

  useEffect(() => { 
    selectNotFoundContent();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, options]);

  useEffect(() => { // important (Not coment this line)
    if (initialOptions && initialOptions.length > 0) {
      setOptions(initialOptions);
    } else {
      debounceFetcher('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOptions]);

  useEffect(() => {
    debounceFetcher(searchValue);
  }, [forceReload, ...dependencies]);

  useEffect(() => {
    if (searchValue !== undefined) {
      debounceFetcher(searchValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <Select
      showSearch
      onDeselect={onDeselect}
      onClear={onClear}
      allowClear
      filterOption={false}
      onSearch={(val) => {
        if (onSearch) onSearch(val);
        debounceFetcher(val); 
      }}
      notFoundContent={notFoundContent}
      onSelect={onSelect}
      ref={inputRef}
      mode={mode}
      searchValue={searchValue}
      dropdownRender={dropdownRender}
      placeholder={placeholder}
      style={style}
      onChange={onChange}
      open={open}
      {...props}
      disabled={disabled}
      options={!useAvatar ? options : undefined}
    >
      {
        useAvatar ? (
          options?.map(element => (
            <Option value={element.value} key={element.value} disabled={element?.disabled ?? false}>
              {element.imageUrl && (
                <Avatar
                  size={24}
                  src={element.imageUrl}
                  shape="square"
                  style={{ marginRight: 10 }}
                />
              )}
              {element.tag ? <Tag>{element.tag}</Tag> : null}
              {element.label}
            </Option>
          ))
        ) : undefined
      }
    </Select>
  );
};

export default DebounceSelect;
