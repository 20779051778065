import React, { useState } from 'react';

export interface ModalHook <T, >{
    visible: boolean;
    isLoading: boolean;
    data: T | undefined,
    show: () => void;
    hide: () => void;
    setData: React.Dispatch<React.SetStateAction<T | undefined>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const useModal = <T, >(initData?: T): ModalHook<T> => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState<T | undefined>(initData);

    const show = ():void => {
        setVisible(true);
    };

    const hide = ():void => {
        setVisible(false);
    };

    return {
        data,
        visible,
        isLoading,
        show,
        hide,
        setLoading,
        setData,
    };
};

export default useModal;
