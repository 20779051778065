import React, { useEffect } from 'react';
import { 
  Avatar, Button, Col, 
  Dropdown, MenuProps, Row, Space, 
} from 'antd';
import useAuth from '@/hooks/useAuth';
import { 
  DownOutlined, 
  LogoutOutlined, 
  NotificationOutlined,
} from '@ant-design/icons';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import useModal from '@/hooks/useModal';
import FeedbackModal from '@/shared/components/FeedbackModal';

const TopbarProfile = () => {
  const auth = useAuth();
  const feedbackModal = useModal();
  const { t } = useCustomTranslation();
  
  const items: MenuProps['items'] = [
    {
      label: t('g.request_help'),
      key: 'request_help',
      icon: <NotificationOutlined />,
      onClick: () => feedbackModal.show(),
    },
    {
      label: 'Cerrar sesión',
      key: 'logout',
      icon: <LogoutOutlined />,
      onClick: () => auth?.logout(),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        {
          feedbackModal.visible ? (
            <FeedbackModal
              isOpen={feedbackModal.visible}
              onClose={() => feedbackModal.hide()} 
            />
          ) : null
        }
        <Dropdown 
          menu={{ items }} 
          placement="bottomRight"
          arrow
          trigger={['click']}
        >
          <Button 
            type="link"
            icon={(
              <Avatar 
                alt="user avatar"
                src={auth?.staff?.photoUrl} 
              />
            )}
            style={{ 
              color: '#212529', 
              padding: 0,
            }}
          >
            <Space align="center">
              {auth?.staff?.name}
              <DownOutlined style={{ marginBottom: '6px' }} />
            </Space>
          </Button>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default TopbarProfile;
