import axiosApiConfig from '@/axiosApiConfig';
import { PaginationResponse } from '@/hooks/usePaginationService';
import IInventoryProduct from '@/interfaces/IInventoryProduct';
import IInventoryProductDetail from '@/interfaces/IInventoryProductDetail';
import { ProductInventory } from '@/interfaces/ProductInventory';
import { RackType } from '@/interfaces/Rack';

interface IGetInventoryProducts {
    warehousesIds?: string | string[];
    productsIds?: string | string[];
    racksIds?: string | string[];
    rackTypes?: RackType[];
    search?: string;
    grouped?: boolean;
}
const getInventoryProducts = async (params?: IGetInventoryProducts): Promise<ProductInventory[]> => {
    const result: any = await axiosApiConfig.get('/inventory_products', {
        params: {
            grouped: true,
            ...params,
        },
    });
  return result.data.data;
};

// export const createInventoryProduct = async (data: ProductInventory): Promise<ProductInventory> => {
//   const result = await axiosApiConfig.post<any>('/inventory_products/', data);
//   return result.data;
// };

// export const editInventoryProduct = async (data: any): Promise<ProductInventory> => {
//     const result = await axiosApiConfig.patch<any>(`/inventory_products/${data.id}`, data);
//     return result.data;
// };

export async function getInventoryProductsPaginated(
    skip: number, 
    limit: number, 
    params?: IGetInventoryProducts,
): Promise<PaginationResponse<IInventoryProduct>> {
    const result : any = await axiosApiConfig.get('/inventory_products', { 
        params: { 
            grouped: true,
            ...params,
            skip,
            limit,
        },
    });
    return result.data;
}

export const getInventoryProductDetails = async (params?: any): Promise<IInventoryProductDetail[]> => {
    const result : any = await axiosApiConfig.get('/inventory_product_details', { ...params });
    return result.data;
  };

interface IValidateInventory {
    rackId: string;
    productId: string;
    warehouseId: string;
    qtyToValidate: number;
}

export const validateInventory = async (data: IValidateInventory): Promise<boolean> => {
    const result = await axiosApiConfig.post<number>('/inventory/validate_by_rack', data);

    const curentInventoryQty = result.data;
    
    return curentInventoryQty >= data.qtyToValidate;
};

interface IWriteOffInventory {
    products: {
        qty: number;
        rackId: string;
        productId: string;
        folios: string[];
      }[],
    warehouseId: string;
}

export const writeOffInventory = async (data: IWriteOffInventory): Promise<void> => {
    const result = await axiosApiConfig.post('/inventory/write_off', data);
    return result.data;
};

export default getInventoryProducts;
