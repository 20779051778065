import React, { FC, useEffect } from 'react';
import { Modal } from 'antd';
import useCustomTranslation from '@/hooks/useCustomTranslation';

interface IFeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const FeedbackModal: FC<IFeedbackModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useCustomTranslation();
  const scriptUrl = process.env.REACT_APP_FEEDBACK_SCRIPT_URL;

  useEffect(() => {
    if (!scriptUrl) return;

    document.getElementById('feedbackWidget')?.remove();

    const script = document.createElement('script');

    script.src = scriptUrl;
    script.id = 'feedbackWidget';
    script.async = true;

    document.body.appendChild(script);
  }, [scriptUrl]);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      title={t('g.request_help')}
      destroyOnClose
    >
      <div id="zsfeedbackwidgetdiv" />
    </Modal>
  );
};

export default FeedbackModal;
